<template>
  <page-container>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form :inline="true" :model="searchModel" style="margin-top: 20px;padding-left: 20px" size="mini">
          <el-form-item label="所属区域:">
            <el-cascader
              ref="myCascader"
              v-model="selectedOptions"
              clearable
              placeholder="请选择区域"
              :options="regionOptions"
              :props="regionProps"
              @change="handleItemChange"
              filterable>
            </el-cascader>
          </el-form-item>
          <!--                <el-form-item label="所属企业:" prop="compName">
                      <el-select
                              v-model="searchModel.compName"
                              placeholder="请选择所属企业"
                              filterable
                              clearable
                              style="width: 200px"
                      >
                          <el-option
                                  v-for="item in companyList"
                                  :key="item.compId"
                                  :label="item.compName"
                                  :value="item.compId"
                          >
                          </el-option>
                      </el-select>
                  </el-form-item>-->
          <el-form-item label="车辆:">
            <el-input v-model="searchModel.busLicPlate" placeholder="选择车辆" style="width: 180px" :readonly="true">
              <el-button slot="append" @click="busDialog = true">选择</el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="开始:">
            <el-date-picker
              v-model="searchModel.startDateTime"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束:">
            <el-date-picker
              v-model="searchModel.endDateTime"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 180px"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{searchText}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-close" v-if="loading" @click="loading = false">停止</el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="mileageReport"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :align="allAlign"
          :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
          :data="tableData">
          <template #buttons>
            <el-button type="primary" icon="el-icon-download" v-if="loading===false" :disabled="tableData.length===0" @click="exportDataEvent">导出</el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60"/>
          <vxe-table-column field="compName" title="所属公司" show-overflow="title" min-width="135" show-header-overflow="title"/>
          <vxe-table-column field="ownerCode" title="自编号" show-overflow="title" min-width="60" show-header-overflow="title"/>
          <vxe-table-column field="busLicPlate" title="车牌号" show-overflow="title" min-width="60" show-header-overflow="title"/>
          <vxe-table-column field="startDateTime" title="开始时间" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="endDateTime" title="结束时间" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="mileage" title="里程(km)" min-width="60" show-overflow="title" show-header-overflow="title"/>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />

      <el-dialog
        title="车辆选择"
        :visible.sync="busDialog"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :destroy-on-close="false"
        v-dialogDrag
        width="500px"
      >
        <div style="width:100%;height:500px">
          <BusTree ref="busTree"/>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="stopSearch()" size="mini">取消</el-button>
          <el-button type="primary" @click="sureSelectBus()" size="mini"
          >确定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import BusTree from '@/views/monitor/components/BusTree'
import { getMileageDataList, exportMileageDataList } from '@/apis/report/busReport'
import { gainRegi } from '@/apis/common'
import { exportToxlsx } from '@/utils/tools'
import moment from 'moment'
export default {
  name: 'mileage-report',
  components: {
    BusTree
  },
  data () {
    return {
      allAlign: 'center',
      tableHeight: 400,
      loading: false,
      tableData: [],
      searchModel: {
        busIds: '',
        busLicPlate: '',
        compName: '',
        startDateTime: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDateTime: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      },
      companyList: [],
      regionOptions: [],
      selectedOptions: [],
      regionProps: {
        //  multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code],
                  regiDto: {
                  }
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      currentBusIndex: 0,
      searchText: '查询',
      busDialog: false,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      }
    }
  },
  methods: {
    stopSearch () {
      this.busDialog = false
    },
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    handleItemChange (val) {
    },
    submitSearch () {
      if (this.searchModel.busIds.length === 0) {
        this.$message({
          message: '请选择车辆',
          type: 'warning'
        })
        this.busDialog = true
        return
      }
      const params = {
        startDateTime: this.searchModel.startDateTime,
        endDateTime: this.searchModel.endDateTime
      }

      if (!params.startDateTime) {
        this.$message({
          message: '请选择开始时间',
          type: 'warning'
        })
        return
      }

      if (!params.endDateTime) {
        this.$message({
          message: '请选择结束时间',
          type: 'warning'
        })
        return
      }

      const startDateTime = moment(params.startDateTime)
      const endDateTime = moment(params.endDateTime)
      const diffSecond = endDateTime.diff(startDateTime, 'seconds')
      if (diffSecond < 0) {
        this.$message({
          message: '开始时间不能晚于结束时间',
          type: 'warning'
        })
        return
      }
      if (diffSecond > 86400 * 30) {
        this.$message({
          message: '查询时间段不能超过30天',
          type: 'warning'
        })
        return
      }
      this.loading = true
      this.tableData = []
      this.currentBusIndex = 0
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    sureSelectBus () {
      const busIds = this.$refs.busTree.$refs.treeRef.getLeafCheckedIds('id') || []
      const busInfoArr = this.$refs.busTree.$refs.treeRef.getLeafCheckedIds('detail') || []
      const busLicPlates = []
      busInfoArr.forEach(item => {
        busLicPlates.push(item.busLicPlate)
      })
      this.searchModel.busLicPlate = busLicPlates.join(',')
      this.searchModel.busIds = busIds.join(',')
      this.busDialog = false
    },
    exportDataEvent () {
      /* this.$refs.xTable1.exportData({
                    filename: '里程报表',
                    sheetName: '里程报表',
                    type: 'xlsx'
                }) */
      exportMileageDataList({
        ...this.searchModel,
        countrId: this.selectedOptions[2] ? this.selectedOptions[2] : undefined,
        cityId: this.selectedOptions[1] ? this.selectedOptions[1] : undefined,
        provinceId: this.selectedOptions[0] ? this.selectedOptions[0] : undefined
      }).then(res => {
        exportToxlsx(res, '里程报表.xlsx')
      })
    },
    refreshData () {
      //   this.searchText = ((this.currentBusIndex / this.searchModel.busIds.length) * 100).toFixed(0) + "%";
      this.loading = false
      getMileageDataList({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        countrId: this.selectedOptions[2] ? this.selectedOptions[2] : undefined,
        cityId: this.selectedOptions[1] ? this.selectedOptions[1] : undefined,
        provinceId: this.selectedOptions[0] ? this.selectedOptions[0] : undefined
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      })
    }
  },
  mounted () {
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        this.regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
      }
    }).catch(val => {
      console.log(val)
    })
    /*    gianComplist({
                pageNum:1,
                pageSize:999
            }).then(res => {
                if (res.code == 200) {
                    this.companyList = res.data.rows
                    console.log('companyList',this.companyList)
                }
            }).catch(res => {
                console.log(res)
            }) */

    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}

</style>
